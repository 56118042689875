import React, { useEffect, useState } from "react";
import ButtonShopFilter from "./buttonShopFilter";

import { useWarehouse } from "../context/warehouseContext";

export default function ShopFilter() {
    const {setFilter} = useWarehouse()
  const [filterButtons, setFilterButtons] = useState([
    { id: "all", label: "Vše", active: true },
    { id: "0_4", label: "0,4l", active: false },
    { id: "5", label: "5l", active: false },
  ]);

  const [filterBy, setFilterBy] = useState("all");

  function filter(e) {
    const pressedButtonID = e.target.id;
    setFilterBy(pressedButtonID);
    filterButtons.forEach(
        (btn) => (btn.active = btn.id === pressedButtonID ? true : false)
    );
    setFilterButtons(filterButtons)
  }
    
   useEffect(() => {
       setFilter(filterBy)
   }, [filterBy, setFilter]);

  return (
      <div className="flex space-x-2 bg-gray-200 px-4 py-2 items-center justify-center rounded-lg">
        <h2 className="hidden md:block mr-4 uppercase font-semibold text-gray-600">Filtr:</h2>
        {filterButtons.map((btn) => {
          const { id, label, active } = btn;
          return (
            <ButtonShopFilter
              key={id}
              id={id}
              label={label}
              active={active}
              onClickAction={filter}
            />
          );
        })}
      </div>
  );
}

import React from "react";
import { useWarehouse } from "../context/warehouseContext";

import Section from "./section";
import ItemCard from "./itemCard";
import ShopMenu from "./shopMenu";

export default function Shop() {
  const { filteredWarehouse } = useWarehouse();

  return (
    <Section header="Obchod" subheader=" ">
      <ShopMenu />
      <div className="grid 2xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-2 gap-x-10 gap-y-20">
        {filteredWarehouse.map((item, index) => (
          <ItemCard key={index} item={item} />
        ))}
      </div>
    </Section>
  );
}

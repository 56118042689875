import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import Shop from "../components/shop";
import CartDetail from "../components/cartDetail";

export default function ShopPage() {
  return (
    <>
      <Helmet>
        <title>E-Shop - Vosky Na Dřevo</title>
      </Helmet>
      <Layout>
        <Shop />
        <CartDetail />
      </Layout>
    </>
  );
}

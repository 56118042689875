async function writeToSheets(row) {

    try {
      /* eslint-disable no-unused-vars */
      const res = await fetch(process.env.GATSBY_SHEETS_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(row),
      });
      /* eslint-disable no-unused-vars */
    } catch (error) {
    console.log("ERROR: ", error);
  }
}

function addItemsToSheetFromCart(cart, warehouse, user) {
  cart.forEach((item) => {
    const newRow = createRowForSheet(item, warehouse, user);
    writeToSheets(newRow);
  });
}

function createRowForSheet(item, warehouse, user) {
  const itemInCart = warehouse.find((element) => element.id === item.id);
    const sumPrice = itemInCart.volume.frontmatter.price * item.count;
    const status = "nová"

  const newRow = {
    Name: user.name,
    Mail: user.mail,
    Phone: user.phone === undefined ? "tel. nezadán" : user.phone,
    Address: user.address,
    Message: user.msg === undefined ? "bez zprávy" : user.msg,
    Product: itemInCart.name,
    Volume: itemInCart.volume.frontmatter.volume,
    Count: item.count,
    Price: itemInCart.volume.frontmatter.price,
    SumPrice: sumPrice,
    Status: status,
  };
  return newRow;
}

export { addItemsToSheetFromCart };

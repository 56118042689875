import React from 'react'
import { useCart } from '../context/cartContext';
import { FaPlus, FaMinus, FaTrash } from "react-icons/fa";

export default function ItemInCartController({ id, justify }) {
    const { addItem, removeItem, deleteItem, getItemCountInCart } = useCart()
    
  return (
    <div className={`flex h-8 space-x-2 items-center ${justify}`}>
        <button
          className="p-2 text-gray-700 bg-gray-200 rounded-lg hover:text-red-500 hover:bg-red-200"
          onClick={() => {
            removeItem(id);
          }}
        >
          <FaMinus />
        </button>
        <div className="px-2 text-xl w-6">{getItemCountInCart(id)}</div>
        <button
          className="p-2 text-gray-700 bg-gray-200 rounded-lg hover:text-green-500 hover:bg-green-200"
          onClick={() => {
            addItem(id);
          }}
        >
          <FaPlus />
        </button>
        <button
          className="p-2 text-gray-500 bg-gray-200 rounded-lg hover:text-red-500 hover:bg-red-200"
          onClick={() => {
            deleteItem(id);
          }}
        >
          <FaTrash />
        </button>
      </div>
    );
}
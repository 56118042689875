import React from "react";
import { Link } from "gatsby";
import { FaShoppingCart } from "react-icons/fa";
import CurrencyFormat from "react-currency-format";

import { useCart } from "../context/cartContext";

export default function CartStatus() {
  const { getCountOfItemsInCart, getValueOfCart } = useCart();
  const cartItemsCount = getCountOfItemsInCart();
  const valueOfCart = getValueOfCart()

  const formattedValueOfCart = (
    <CurrencyFormat
      value={valueOfCart}
      suffix=" Kč"
      thousandSeparator=" "
      displayType={"text"}
      renderText={(value) => (
        <div className="bg-transparent">{value}</div>
      )}
    />
  );

  return (
    <div className="flex content-center justify-center items-center space-x-2 px-4 py-2 text-lg">
      <Link
        to="#cart"
        className="flex justify-center content-center items-center text-gray-600 hover:text-gray-500 "
      >
        <FaShoppingCart />
        <span className="flex ml-2">Košík:</span>
        <span className=" m-2">{formattedValueOfCart}</span>
        <span className="">({cartItemsCount})</span>
      </Link>
    </div>
  );
}

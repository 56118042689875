import React from "react";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CurrencyFormat from "react-currency-format";

import ItemInCartController from "./itemInCartControl";

export default function CartDetailRow({ id, count, item }) {

  const formattedVolume = `${item.volume.frontmatter.volume}l`;
  // const img = getImage(item.image);
  const itemPrice = item.volume.frontmatter.price;
  const sumPrice = count * itemPrice;

  const formattedItemPrice = (
    <CurrencyFormat
      value={itemPrice}
      suffix=" Kč"
      thousandSeparator=" "
      displayType={"text"}
      renderText={(value) => (
        <div className="bg-transparent text-lg">{value}</div>
      )}
    />
  );

  const formattedSumPrice = (
    <CurrencyFormat
      value={sumPrice}
      suffix=" Kč"
      thousandSeparator=" "
      displayType={"text"}
      renderText={(value) => (
        <div className="bg-transparent text-lg">{value}</div>
      )}
    />
  );

  

  return (
    <div className="grid grid-cols-3 lg:grid-cols-6 mt-4 gap-4">
      <img
        src={item.image}
        alt={item.name}
        className="object-contain h-16 w-24"
      />
      {/* <GatsbyImage image={img} alt={item.name} className="h-16 w-24" /> */}
      <div>{item.name}</div>
      <div>{formattedVolume}</div>
      <ItemInCartController id={id} />
      <div>{formattedItemPrice}</div>
      <div>{formattedSumPrice}</div>
    </div>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "@formspree/react";
import { FaPaperPlane } from "react-icons/fa";

import { useCart } from "../context/cartContext";
import { useWarehouse } from "../context/warehouseContext";
import { addItemsToSheetFromCart } from "../utils/sheetsManager";
import CartDetailRow from "./cartDetailRow";
import Section from "./section";
import useModal from "../hooks/useModal";

export default function CartDetail() {
  const { warehouse } = useWarehouse();
  const { cart, clearCart, getValueOfCart } = useCart();
  const { Modal, modalIsHidden, setModalIsHidden } = useModal();

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
  const [address, setAddress] = useState("");
  const [order, setOrder] = useState("");

  const [state, handleSubmit] = useForm(process.env.GATSBY_FORMSPREE_ORDER_ID, {
    data: {
      saveOrder: () => {
        const user = {
          name: name,
          mail: mail,
          phone: phone,
          address: address,
          msg: msg,
        };
        addItemsToSheetFromCart(cart, warehouse, user);
        clearForm();
        clearCart();
        setModalIsHidden(false);
      },
    },
  });

  const inputFieldStyle =
    "w-full px-6 py-2 bg-gray-300 rounded focus:outline-none focus:shadow";
  const submitButtonStyle =
    "bg-green-700 hover:bg-green-600 text-gray-50 px-8 py-2 font-medium text-xl rounded-lg mt-4";

  const valueOfCart = getValueOfCart();

  function clearForm() {
    setName("");
    setMail("");
    setPhone("");
    setMsg("");
    setAddress("");
    setOrder("");
  }

  const formattedValueOfCart = (
    <CurrencyFormat
      value={valueOfCart}
      suffix=" Kč"
      thousandSeparator=" "
      displayType={"text"}
      renderText={(value) => (
        <div className="bg-transparent text-lg">{value}</div>
      )}
    />
  );

  const prepareOrderToMail = useCallback(() => {
    var orderSumPrice = 0;
    var tempOrder = `OBJEDNÁVKA
    ----------------------
    PRODUKT - BALENÍ - POČET KUSŮ - JEDNOTKOVÁ CENA - CELKOVÁ CENA
        `;
    cart.forEach((item) => {
      const itemInCart = warehouse.find((element) => element.id === item.id);
      const sumPrice = itemInCart.volume.frontmatter.price * item.count;
      const tempOrderRow = `${itemInCart.name} - ${itemInCart.volume.frontmatter.volume}l - ${item.count}ks - ${itemInCart.volume.frontmatter.price} Kč - ${sumPrice} Kč
            `;
      tempOrder = tempOrder + tempOrderRow;
      orderSumPrice = orderSumPrice + sumPrice;
    });

    const orderFooter = `CELKOVÁ VÝŠE OBJEDNÁVKY: ${orderSumPrice} Kč`;
    tempOrder =
      tempOrder +
      `----------------------
    ` +
      orderFooter;

    setOrder(tempOrder);
  }, [cart, warehouse]);

  useEffect(() => {
    prepareOrderToMail();
  }, [cart, prepareOrderToMail]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsHidden(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, [modalIsHidden, setModalIsHidden]);

  return (
    <Section header="Košík" subheader=" " id="cart">
      <Modal>
        <div className="flex w-full text-center justify-center mb-4">
          <FaPaperPlane className="text-3xl " />
        </div>
        <div className="uppercase mb-4 font-semibold text-lg">děkujeme,</div>
        <div className="">
          Vaše objednávka byla odeslána. V blízké době se Vám ozveme.
        </div>
      </Modal>
      {cart.length === 0 && (
        <div className="text-center text-xl text-gray-500">
          V košíku není momentálné žádné zboží
        </div>
      )}
      {cart.length > 0 && (
        <div className="space-y-6">
          <div className="grid grid-cols-3 lg:grid-cols-6 mt-4 gap-4">
            <div>Foto</div>
            <div>Název</div>
            <div>Objem</div>
            <div>Počet kusů</div>
            <div>Jednotková cena</div>
            <div>Celková cena</div>
          </div>
          {cart.map((item, index) => {
            const itemInCart = warehouse.find(
              (element) => element.id === item.id
            );
            return (
              <div key={index}>
                <hr />
                <CartDetailRow
                  id={item.id}
                  count={item.count}
                  item={itemInCart}
                />
              </div>
            );
          })}
          <hr />
          <div className="flex content-center items-center justify-end space-x-4 mr-0">
            <div>CELKEM:</div>
            {formattedValueOfCart}
          </div>
          <form onSubmit={handleSubmit} className="space-y-2">
            <div className="flex justify-center space-x-4">
              <div className="flex-auto space-y-2">
                <div>
                  <input
                    className={inputFieldStyle}
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Jméno a příjmení *"
                    required
                  />
                </div>
                <div>
                  <input
                    className={inputFieldStyle}
                    type="mail"
                    id="mail"
                    name="mail"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="Email *"
                    required
                  />
                </div>
                <div>
                  <input
                    className={inputFieldStyle}
                    type="tel"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Telefon"
                  />
                </div>
              </div>
              <div className="flex-auto">
                <textarea
                  className={inputFieldStyle + " h-[8.5rem]"}
                  type="text"
                  id="address"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Doručovací adresa *"
                  required
                />
              </div>
            </div>

            <div>
              <textarea
                className={inputFieldStyle + " h-32"}
                type="text"
                id="msg"
                name="msg"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                placeholder="Zpráva"
              />
            </div>
            <div>
              <textarea
                className="hidden"
                type="text"
                id="order"
                name="order"
                value={order}
                readOnly
              />
            </div>
            <div className="text-xs text-gray-400">
              Pole označená hvězdičkou (*) jsou povinná
            </div>
            <div className="text-center">
              <button
                className={submitButtonStyle}
                type="submit"
                name="send"
                disabled={state.submitting}
              >
                Odeslat objednávku
              </button>
            </div>
          </form>
        </div>
      )}
    </Section>
  );
}

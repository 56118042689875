import React from "react";
import CurrencyFormat from "react-currency-format";

import { useCart } from "../context/cartContext";
import ItemInCartController from "./itemInCartControl";
import useModal from "../hooks/useModal";

//TODO: Add Modal Windows
export default function ItemCard({ item }) {
  const { addItem, isItemInCart } = useCart();
  const {Modal, showModal} = useModal()
 
  const formattedPrice = (
    <CurrencyFormat
      value={item.volume.frontmatter.price}
      suffix=" Kč"
      thousandSeparator=" "
      displayType={"text"}
      renderText={(value) => (
        <div className="bg-transparent text-lg font-semibold">{value}</div>
      )}
    />
  );
  const formattedVolume = `${item.volume.frontmatter.volume}l`;


  return (
    <>
      <Modal>
        <div className="md:flex bg-white rounded text-center space-y-4 md:text-left md:space-x-4 p-4">
          <div className="flex justify-center items-center">
            <img
              src={item.image}
              alt={item.name}
              className="object-contain  w-2/3 md:w-full md:h-full  pb-4 md:pr-4"
            />
          </div>

          <div>
            <div className="text-3xl truncate">{item.name}</div>
            <div className="mt-4">
              <div className="text-lg">Objem balení: {formattedVolume}</div>
              <div className="flex justify-center md:justify-start space-x-2 items-center">
                <span>Cena: </span>
                {formattedPrice}
              </div>
            </div>
            <article className="font-light mt-4">
              {item.tint.frontmatter.properties}
            </article>
            <div className="text-center mt-8 md:mt-4">
              {isItemInCart(item.id) === false ? (
                <button
                  onClick={() => {
                    addItem(item.id);
                  }}
                  className="h-8 px-4 py-1 w-full rounded-md bg-green-800 text-green-50"
                >
                  Koupit
                </button>
              ) : (
                <ItemInCartController id={item.id} justify="justify-end" />
              )}
            </div>
          </div>
        </div>
      </Modal>
      <div className="py-8 px-4 rounded-sm bg-white shadow-md ">
        <button className="w-full h-32 text-center mb-6" onClick={showModal}>
          <img
            src={item.image}
            alt={item.name}
            className="object-contain w-full h-full"
          />
        </button>
        <button className="w-full" onClick={showModal}>
          <div className="mb-2">
            <div className="font-medium text-center text-lg truncate">
              {item.name}
            </div>
            <div className="text-center text-sm">
              Objem balení: {formattedVolume}
            </div>
            <div className="text-right">{formattedPrice}</div>
          </div>
        </button>

        <div className="text-center">
          {isItemInCart(item.id) === false ? (
            <button
              onClick={() => {
                addItem(item.id);
              }}
              className="h-8 px-4 py-1 w-full rounded-md bg-green-800 text-green-50"
            >
              Koupit
            </button>
          ) : (
            <ItemInCartController id={item.id} justify="justify-end" />
          )}
        </div>
      </div>
    </>
  );
}

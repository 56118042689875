import React from "react";
import CartStatus from "./cartStatus";
import ShopFilter from "./shopFilter";

export default function ShopMenu() {
  return (
    <div className="md:flex items-center justify-center align-middle ">
      <ShopFilter />
      <div className="flex-grow"></div>
      <CartStatus />
    </div>
  );
}
